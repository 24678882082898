:root {
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  max-height: 100vh;
  overflow: hidden;
  background-color: #f0f4f7 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

[data-rbd-drag-handle-context-id="0"] {
  cursor: auto !important;
}

.Pane {
  overflow: hidden;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}

/* Layers */
#root > div > .SplitPane > .Pane.vertical.Pane1 {
  margin-top: 41px;
  z-index: 110;
  overflow-x: hidden;
}

/* Canvas */
#root > div > .SplitPane .SplitPane > .Pane.vertical.Pane1 {
  margin-top: 41px;
}

/* Inspector */
#root > div > .SplitPane .SplitPane > .Pane.vertical.Pane2 {
  margin-top: 41px;
  z-index: 110;
}

@keyframes from-left {
  0% {
    transform: translate3d(-50%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes from-right {
  0% {
    transform: translate3d(50%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes from-left-alt {
  0% {
    transform: translate3d(-50%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes from-right-alt {
  0% {
    transform: translate3d(50%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes replace {
  0% {
    transform: translate3d(0, -100px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes replace-alt {
  0% {
    transform: translate3d(0, -100px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.slide-back {
  animation-timing-function: cubic-bezier(0.05, 0.69, 0.14, 1);
  animation-duration: 0.2s;
  animation-name: from-left;
}

.slide-forward {
  animation-timing-function: cubic-bezier(0.05, 0.69, 0.14, 1);
  animation-duration: 0.2s;
  animation-name: from-right;
}

.replace {
  animation-timing-function: cubic-bezier(0.05, 0.69, 0.14, 1);
  animation-duration: 0.2s;
  animation-name: replace;
}

.slide-back-alt {
  animation-timing-function: cubic-bezier(0.05, 0.69, 0.14, 1);
  animation-duration: 0.2s;
  animation-name: from-left-alt;
}

.slide-forward-alt {
  animation-timing-function: cubic-bezier(0.05, 0.69, 0.14, 1);
  animation-duration: 0.2s;
  animation-name: from-right-alt;
}

.replace-alt {
  animation-timing-function: cubic-bezier(0.05, 0.69, 0.14, 1);
  animation-duration: 0.2s;
  animation-name: replace-alt;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#canvas-pane.space-press #canvas * {
  pointer-events: none !important;
}

.react-draggable-dragging ion-app *,
.is-wheeling ion-app * {
  pointer-events: none !important;
}
